.dynamic-table {
    &.audit {
        .table-header {
            height: 30px !important;
            padding: 8px !important;
            font-weight: normal !important;
            background: #fbfbfb !important;
            &:not(.p-highlight) {
                color: #777777 !important;
            }
        }

        .table-cell {
            @apply text-gray-500 bg-gray-fb;
            color: #777777;
            .p-column-title {
                @apply min-w-30p inline-block font-bold;
                padding: 0.4rem;
                margin: -0.4rem 1rem -0.4rem -0.4rem;
            }
        }
    }

    .status-cover {
        @apply flex space-x-2 items-center truncate;

        .status {
            @apply w-3 h-3 rounded-full;

            &__U_STT_1 {
                @apply bg-primary;
            }
            &__U_STT_2 {
                @apply bg-gray-500;
            }
            &__U_STT_3 {
                @apply bg-gray-500;
            }
            &__E_STT_0 {
                @apply bg-gray-500;
            }
            &__E_STT_1 {
                @apply bg-primary;
            }
            &__E_STT_2 {
                @apply bg-primary;
            }
            &__E_STT_3 {
                @apply bg-gray-500;
            }
            &__E_STT_4 {
                @apply bg-primary;
            }
            &__SC_STT_1 {
                @apply bg-primary;
            }
            &__SC_STT_2 {
                @apply bg-primary;
            }
            &__SC_STT_3 {
                @apply bg-primary;
            }
            &__SC_STT_4 {
                @apply bg-primary;
            }
            &__SC_STT_5 {
                @apply bg-primary;
            }
            &__SC_STT_6 {
                @apply bg-primary;
            }
            &__SR_STT_1 {
                @apply bg-primary;
            }
            &__SR_STT_2 {
                @apply bg-gray-500;
            }
            &__MS_STT_1 {
                @apply bg-primary;
            }
            &__MS_STT_2 {
                @apply bg-gray-500;
            }
            &__DI_STT_0 {
                @apply bg-gray-500;
            }
            &__DI_STT_R {
                @apply bg-red-500;
            }
            &__DI_STT_G {
                @apply bg-lime-500;
            }
            &__SY_STT_1 {
                @apply bg-secondary-light;
            }
            &__SY_STT_2 {
                @apply bg-primary;
            }
            &__SY_STT_3 {
                @apply bg-red-500;
            }
            &__AU_STT_0 {
                @apply bg-gray-500;
            }
            &__AU_STT_1 {
                @apply bg-primary;
            }
            &__READ_true {
                @apply bg-transparent;
            }
            &__READ_false {
                @apply bg-primary;
            }
            &__VALID {
                @apply bg-primary;
            }
            &__INVALID {
                @apply bg-red-500;
            }
        }
    }
    .sync-status-cover {
        @apply flex space-x-2 items-center;

        .sync-status {
            @apply w-3 h-3 rounded-full;

            &__true {
                @apply bg-primary;
            }

            &__false {
                @apply bg-gray-500;
            }
        }
    }

    .action-cover {
        @apply flex justify-center;

        .pi:not(.ex) {
            &:hover,
            &:focus {
                @apply text-sky-600;
            }
        }

        &.disabled {
            @apply opacity-20 cursor-default;
        }
    }

    .label-link {
        @apply text-secondary underline cursor-pointer font-bold;
    }

    .red-act {
        @apply hover:text-red-500 focus:text-red-500;
    }

    .green-act {
        @apply hover:text-primary focus:text-primary;
    }
}
