@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './primeng/dropdown.scss';
@import './primeng/input.scss';
@import './primeng/theme.scss';
@import './primeng/panel.scss';
@import './primeng/calendar.scss';
@import './primeng/filter';
@import './primeng/tabview.scss';
@import './components/dynamic-table.scss';
@import './typography';

@for $i from 2 through 10 {
    .truncate-#{$i} {
        word-break: break-word;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-box-orient: vertical;
        /* autoprefixer: off */
        display: -webkit-box;
        white-space: pre-line;
        -webkit-line-clamp: $i;
    }
}

p-dialog {
    .p-component-overlay {
        // @apply bg-secondary opacity-90;
    }

    .p-component-overlay-enter {
        @apply animate-none;
    }
}

.cursor-pointer {
    @apply focus:ring-2 outline-none;
    box-shadow: none !important;
}

::ng-deep {
    input::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        @apply text-red-500; // text-label-3 font-bold;
        opacity: 1; /* Firefox */
    }
    input::-ms-input-placeholder {
        /* Microsoft Edge */
        @apply text-label-3 font-bold;
    }
}

section.page-section {
    @apply flex flex-col gap-3 p-4 bg-white shadow;

    margin-bottom: 2px;

    // For mobile phone resolutions
    @media only screen and (max-width: 479px) {
        @apply px-2;
    }

    .separator {
        @apply flex flex-col items-center w-full;

        & > div {
            @apply border-b w-full;
        }

        .full {
            @apply w-full;
        }

        .long {
            @apply w-4/5;
        }

        .half {
            @apply w-1/2;
        }
    }
}

section.page-header {
    & > *:not(:first-child) {
        @apply pt-3 border-t;
    }
}

section.transparent {
    background: none;
    box-shadow: none;
}
