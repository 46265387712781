@font-face {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 400;
    src: url('../font/Calibri-Regular.ttf');
    -webkit-font-smoothing: [ auto ];
}

@font-face {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 700;
    src: url('../font/Calibri-Bold.ttf');
    -webkit-font-smoothing: [ auto ];
}

/*---------------- Global styles ---------------- */

* {
    font-family: 'Calibri';
}

// body {
//     // min-width: 1300px;
//     min-width: 640px;

//     // @apply overflow-y-hidden;
// }


:root {
    --app-height: 100%;
}

html,
body {
    padding: 0;
    margin: 0;
    overflow: hidden;
    width: 100vw;
    // height: 100vh;
    min-width: 240px;
    @media not all and (hover:hover) {
        height: var(--app-height);
    }
    height: var(--app-height);

}