.p-panel-header {
    @apply bg-white;
}

.p-panel-content {
    // @apply overflow-hidden;
}

.p-panel-title {
    @apply text-secondary text-lg;
    font-weight: normal !important;
}